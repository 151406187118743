import { Env } from "~/types";

export var env = import.meta.env.VITE_ENV as Env;
if (!env) {
  env = "Staging";
}

type Status = "enabled" | "disabled";

export type Config = {
  env: Env;
  baseUrl: string;
  enableErrorLoggingToSentry: boolean;
  razorpayKey: string;
  isRazorpayEnabled: boolean;
  bannerPath: string;
  showErrorMessageInErrorView: boolean;
  clientBannerBasePath: string;
  clientBannerFileName: string;
  paths: {
    homePageUrl: string;
    categoryPageBasePath: string;
  };
  logging: {
    router: "enabled" | "disabled";
    fraudChecks: "enabled" | "disabled";
    serverRequests: "enabled" | "disabled";
  };
  clientIdPrefix: Record<string, string>;
  mixpanelEvents: {
    enabledForWebStore: boolean;
    enabledForSdk: boolean;
    enabledForRnr: boolean;
  };
  axisClientId: string;
};

const defaultConfig = {
  logging: {
    router: "disabled" as Status,
    fraudChecks: "disabled" as Status,
    serverRequests: "disabled" as Status,
  },
  paths: {
    homePageUrl: "/",
    categoryPageBasePath: "/shop-by-category/",
  },
  clientIdPrefix: {
    onepercent: "onepercent",
    visitHealth: "visit-health",
  },
  isRazorpayEnabled: false,
  mixpanelEvents: {
    enabledForWebStore: false,
    enabledForSdk: false,
    enabledForRnr: false,
  },
};

const configs: {
  Prod: Config;
  Preprod: Config;
  Staging: Config;
} = {
  Prod: {
    ...defaultConfig,
    env: "Prod",
    baseUrl: "https://api.myhubble.money",
    enableErrorLoggingToSentry: true,
    razorpayKey: "rzp_live_VjACe6SdHqHjuK",
    bannerPath: "home_page/banners_web.json",
    showErrorMessageInErrorView: false,
    clientBannerBasePath: "partners",
    clientBannerFileName: "home_banners.json",
    mixpanelEvents: {
      enabledForWebStore: true,
      enabledForSdk: false,
      enabledForRnr: true,
    },
    axisClientId: "axisbank-llE1XBk0",
  },
  Preprod: {
    ...defaultConfig,
    env: "Preprod",
    baseUrl: "https://api.myhubble.money",
    enableErrorLoggingToSentry: true,
    razorpayKey: "rzp_live_VjACe6SdHqHjuK",
    bannerPath: "home_page/banners_web.json",
    showErrorMessageInErrorView: false,
    logging: {
      router: "enabled",
      fraudChecks: "enabled",
      serverRequests: "disabled",
    },
    clientBannerBasePath: "partners",
    clientBannerFileName: "home_banners.json",
    axisClientId: "acme-code-3241c2c1",
  },
  Staging: {
    ...defaultConfig,
    env: "Staging",
    baseUrl: "https://api.dev.myhubble.money",
    enableErrorLoggingToSentry: false,
    razorpayKey: "rzp_test_UaXfewLGPZYo8Y",
    bannerPath: "home_page/banners_web.staging.json",
    showErrorMessageInErrorView: true,
    logging: {
      router: "enabled",
      fraudChecks: "enabled",
      serverRequests: "disabled",
    },
    clientBannerBasePath: "partners",
    clientBannerFileName: "home_banners.json",
    axisClientId: "acme-code-3241c2c1",
  },
};

export const config = configs[env];

export const HUBBLE_CLIENT_ID = "hubble";

export const CSS_FILES = {
  default: "/styles/main.css",
  "mock-niyo": "/styles/mobikwik.css",
};

export const FONTS = {
  default: [
    "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Krona+One&display=swap",
  ],
  rnr: ["https://fonts.googleapis.com/css2?family=Sacramento&display=swap"],
  sdk: [
    {
      "mock-niyo":
        "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap",
    },
  ],
};

export const RNR_PAGE_TITLE = "Gifts & Rewards - By Hubble";
